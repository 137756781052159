.splide {
  .testimonial {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: $testimonial-width;
    background-color: var(--qkn-light-blue, #F3F8FD);
    border-radius: $border-radius-default;
    position: relative;
    // margin: auto;

    @include bp(lg, max) {
      width: 95%;
    }

    @include bp(639px, max) {
      width: 98.773%;
    }
  }

  .testimonial-stars {
    display: flex;
    position: relative;
    color: var(--star-yellow, #FFE629);
    height: 25px;
    margin-top: 40px;
    margin-left: 40px;
  }

  .testimonial-star {
    width: 22px;
    height: 22px;
    margin-right: 1px;
  }

  .testimonial-quote {
    position: relative;
    font-size: 17px;
    margin: 0px 40px;
    height: auto;
    margin-bottom: 8px;
    margin-top: 10px;
    color: var(--darkGray, #18181F);
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;

    * {
      display: inline;
    }
  }

  .testimonial-author {
    position: relative;
    margin-bottom: 40px;
    margin-left: 42px;
    gap: 16px;
    margin-top: auto;

    .trustpilot_logo {
      width: 103px;
      height: auto;
    }
  }

  .testimonial-author.hug {
    height: 47px;
  }

  .testimonial-author>p {
    margin: 0;
    padding: 0;
  }

  .testimonial-name {
    position: relative;
    color: var(--qkn-blurple);
    font-weight: 600;
    width: 140px;
    height: 22px;
    text-align: left;
  }
}