// styles for the grid slider
.grid-slider.splide {
  .slider-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0px 10% 32px 10px;
  
    @include bp(lg) {
      margin: 0px 10% 64px 10px;
    }
  
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }

  .splide__arrows {
    display: flex;
    gap: 12px;

    &.rc-slide-arrows {
      padding-left: 10px;
      margin-bottom: 40px;

      @include bp(md, max) {
        margin-bottom: 0px;
      }

      @include bp(640px) {
        padding-left: 10.5%;
      }

      @include bp(md) {
        position: absolute;
        top: -1%;
        right: 10%;
        margin-bottom: 0;
        padding-left: 0;
      }

      @include bp(lg) {
        top: 3%;
      }
    }

    // Arrow Tweaks
    .splide__arrow {
      position: unset;
      border-radius: 100%;
      opacity: 1;

      @include bp(lg) {
        img {
          margin-top: 5px;
        }
      }

      @include bp(md, max) {
        width: 48px;
        height: 48px;

        img {
          margin-top: 5px;
        }
      }
    }

    .splide__arrow.splide__arrow--prev {
      transform: scaleX(-1);
      opacity: 1;
      background: var(--qkn-light-blue, #F3F8FD);
    }

    .splide__arrow.splide__arrow--next {
      background: var(--qkn-light-blue, #F3F8FD);
    }

    .splide__arrow.splide__arrow--prev,
    .splide__arrow.splide__arrow--next {
      @include bp(md, max) {
        &.rc-slider-arrow {
          position: initial;
          margin: 0;
        }
      }
    }

    .splide__arrow.splide__arrow--next:enabled:hover,
    .splide__arrow.splide__arrow--prev:enabled:hover {
      background: var(--qkn-blurple, #471CFF);

      img {
        filter: invert(1);
      }
    }
  }

  .splide__track {
    @include bp(lg, max) {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include bp(lg) {
      width: 1150px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 64px;
      margin-bottom: 64px;
    }

    @include bp(md, max) {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      left: 0px;
      right: 0px;
    }
  }

  .cta {
    display: flex;
    justify-content: center;
  }
}