  .rc-slider {
  // gap: 19px;

  .splide__slide {
    width: 308px;

    @include bp(md) {
      width: 365px;
    }
  }

  .rec-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .slide-link {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      text-decoration: none;
      z-index: 1;

      &:hover {
        background: none;
      }
    }

    .corner-icons {
      position: absolute;
      bottom: 0px;
      right: 0px;

      &.left-curve {
        right: 53px;
      }

      &.top-curve {
        bottom: 52px;
      }
    }

    .slide-img {
      border-radius: 12px;
      position: relative;

      .main-img {
        width: 100%;
        height: 174px;
        border-radius: 12px;

        @include bp(md) {
          height: 206px;
        }
      }
    }

    .rc-content {
      padding-top: 16px;
      height: 100%;

      .rc-tags {
        span {
          font-size: 14px;
          color: var(--qkn-light-gray);
        }
      }
    }

    .rc-title {
      padding-top: 8px;
      color: var(--qkn-medium-gray);
      font-size: 21px;

      @include bp(md, max) {
        font-size: 26px;
      }
    }

    &.layout2 {
      .slide-img {
        border-radius: unset;

        .main-img {
          border-radius: 15px 15px 0px 0px;
        }
      }

      .rc-content {
        padding: 13px 32px 51px 32px;
      }
    }

    &.rc-bg-white {
      background-color: var(--white);
    }

    &.rc-bg-gray {
      background-color: var(--card-gray);
    }
  }
}