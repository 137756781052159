// Shared slider styles
$testimonial-width: 366px;
$arrow-diameter: 60px;

.slider-tagline {
  color: black;
  font-size: 48px;
  max-width: fit-content;
  text-align: left;
  font-weight: 600;
  letter-spacing: -1.44px;
}

.splide__arrows {
  .splide__arrow {
    height: $arrow-diameter;
    width: $arrow-diameter;
  
    &.splide__arrow--prev:disabled {
      opacity: 0.5;
    }

    &.splide__arrow--prev {
      transform: scaleX(-1);
      opacity: 1;
      background: var(--qkn-light-blue, #F3F8FD);
    }

    &.splide__arrow--next:disabled {
      opacity: 0.5;
    }

    &.splide__arrow--next {
      transform: none;
      opacity: 1;
      background: var(--qkn-light-blue, #F3F8FD);
    }
  }
}
